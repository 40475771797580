@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/Lato-Italic.ttf') format('truetype');
  font-style: italic;
}

:root {
  --header-height: 80px;
  --color-primary-bg: #000000;
  --color-primary: #089ad6;
  --color-gray-text: #8B8B8B;
  --color-red-alert: #DE033E;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

html, body {
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#app-container {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

body {
  background-color: var(--color-primary-bg);
  font-family: 'Lato', sans-serif;
}

.single-text-row {
  color: white;
  text-align: center;
}

.btn-no-focus:focus {
  outline: none;
  box-shadow: none;
}

.bg-popup-custom {
  background-color: #222326 !important;
}

.bg-header-margin {
  margin-top: 0 !important;
}

.modal-content {
  background: transparent !important;
}

.modal-body {
  border-radius: 5px !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.starbucks {
  background-color: #0E4C2E;
}

/* FIX FOR APPLE PAY BUTTON LABEL */
.adyen-checkout__applepay__button::after{
  content:'Pay with Apple'
}
